import React, { lazy, Suspense } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Home from './layouts/Home/Home.js'
import ReactGA from "react-ga4";


import DashboardLayout from './layouts/Dashboard/DashboardLayout.js';
import PrepareDocument from './layouts/DocFlow/PrepareDocument/PrepareDocument.js';
import AddSigners from './layouts/DocFlow/PrepareDocument/AddSigners.js';
import ReviewSendEmail from './layouts/DocFlow/PrepareDocument/ReviewSendEmail.js';
import SelectFlowType from './layouts/DocFlow/PrepareDocument/SelectFlowType.js';
import UploadDocTemplate from './layouts/DocFlow/PrepareDocument/UploadDocTemplate.js';
import SignPageLayout from './layouts/DocFlow/SignPageLayout/SignPageLayout.js';
import SignedDocumentLayout from './layouts/DocFlow/SignedDocument/SignedDocumentLayout';
import TemplateLayout from './layouts/DocFlow/TemplateLayout/TemplateLayout.js';
import TemplatesLayout from './layouts/Templates/TemplatesLayout.js';
import OpenedDocumentLayout from './layouts/DocFlow/OpenedDocument/OpenedDocumentLayout.js';
import LoginLayout from './layouts/Login/LoginLayout.js';
import RegisterLayout from './layouts/Login/RegisterLayout.js';
// import LandingPage from './layouts/CustomerLanding/LandingPage.js';
import TimeStampLayout from './layouts/TimeStamp/TimeStampLayout'
import ValidateTimestampLayout from './layouts/TimeStamp/ValidateTimestampLayout'


const LandingPage = lazy(() =>  import('./layouts/CustomerLanding/LandingPage.js'));
// const Home = lazy(() =>  import('./layouts/Home/Home.js'));
// const DashboardLayout = lazy(() =>  import('./layouts/Dashboard/DashboardLayout'));
// const PrepareDocument = lazy(() =>  import('./layouts/DocFlow/PrepareDocument/PrepareDocument'));
// const AddSigners = lazy(() =>  import('./layouts/DocFlow/PrepareDocument/AddSigners'));
// const ReviewSendEmail = lazy(() =>  import('./layouts/DocFlow/PrepareDocument/ReviewSendEmail.js'));
// const SelectFlowType = lazy(() =>  import('./layouts/DocFlow/PrepareDocument/SelectFlowType'));
// const UploadDocTemplate = lazy(() =>  import('./layouts/DocFlow/PrepareDocument/UploadDocTemplate'));
// const SignPageLayout = lazy(() =>  import('./layouts/DocFlow/SignPageLayout/SignPageLayout'));
// const SignedDocumentLayout = lazy(() =>  import('./layouts/DocFlow/SignedDocument/SignedDocumentLayout'));
// const TemplateLayout = lazy(() =>  import('./layouts/DocFlow/TemplateLayout/TemplateLayout'));
// const TemplatesLayout = lazy(() =>  import('./layouts/Templates/TemplatesLayout'));
// const OpenedDocumentLayout = lazy(() =>  import('./layouts/DocFlow/OpenedDocument/OpenedDocumentLayout'));
// const LoginLayout = lazy(() =>  import('./layouts/Login/LoginLayout.js'));
// const RegisterLayout = lazy(() =>  import('./layouts/Login/RegisterLayout.js'));


function App() {
  try {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
  } catch (error) {
    console.log("Error initializing google analytics");
  }

  return (
    <div className="app-root">
      <Router>
      <Suspense fallback={<p>Loading... Waiting for the satellites to align...</p>}>
        <Routes>
          <Route path='/login' element={<LoginLayout />} />
          <Route path='/register' element={<RegisterLayout />} />
          <Route path='/wow' element={<LandingPage />} />
          <Route path='/about' element={<LandingPage />} />
          <Route path='/' element={<Home />} >
            <Route path='/timestamp' element={<TimeStampLayout />} />
            <Route path='/timestamp/validate' element={<ValidateTimestampLayout />} />
            <Route path='/template/:id' element={<TemplateLayout />} />
            <Route path='/templates' element={<TemplatesLayout />} />
            <Route path='/signed/:id' element={<SignedDocumentLayout />} />
            <Route path='/signed' element={<SignedDocumentLayout />} />
            <Route path='/sign/:id' element={<SignPageLayout />} />
            <Route path='/sign' element={<SignPageLayout />} />
            <Route path='/document/:id' element={<OpenedDocumentLayout />} />
            <Route path='/prepare' element={<PrepareDocument />} />
            <Route path='/selectflow' element={<SelectFlowType />} />
            <Route path='/upload' element={<UploadDocTemplate />} />
            <Route path='/addsigners' element={<AddSigners />} />
            <Route path='/review' element={<ReviewSendEmail />} />
            <Route path='/dashboard' element={<DashboardLayout />} />
            <Route path='/' element={<SelectFlowType />} />
          </Route>
        </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default React.memo(App);
