import React, {useEffect, useState} from 'react'
import TextField from '@mui/material/TextField';

import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import PrepareDocStepper from './PrepareDocStepper'
import LottiePlayer from '../../../components/LottiePlayer';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as animation from '../../../assets/lottiefiles/send-email.json'
import { setEmailBodyData } from '../../../actions/docflow/documentFlowAction';
import { createDocumentWithPDF as createDocumentAction } from '../../../actions/api/document/documentAction';
import './styles.scss'

function ReviewSendEmail() {

    const navigate = useNavigate();  
    const dispatch = useDispatch();
    const documentCreationRequestData = useSelector((state) => state.docflow.documentCreationRequestData);
    const signerData = useSelector((state) => state.docflow.signerData);
    const selectedFileUploadData = useSelector(
        (state) => state.docflow.selectedFileUploadData
      );
    const [reviewData, setReviewData] = useState({
        subject:documentCreationRequestData.title, 
        body:"Hi,\n\nYou have been requested to sign a document. \nPlease review and sign the document at your earliest convenience. \n\n"
    });

    const handleReviewInputChange = (e) => {
        e&&e.preventDefault();
        let name = e.target.name;
        setReviewData(prevreviewData=>{
            prevreviewData[name] = e.target.value;
            return {...prevreviewData}
        })
    }

    const createDocumentRequest = async () => {

        let {title, documentFieldsData, documentPDFURL}=documentCreationRequestData;

        const createdDocumentsPromises = Object.values(signerData).map(async (signer)=>{
            let documentRequest = {
                title,
                fileUploadData: selectedFileUploadData||"",
                documentFieldsData,
                description: title,
                sentTo: signer.email,
                emailToSendData: reviewData,
                documentPDFURL
            }
            const createdDocument = await createDocumentAction(documentRequest);
            return createdDocument;
        })

        const createdDocuments = await Promise.all(createdDocumentsPromises);

        if(createdDocuments.length>1){
            navigate('/');
        }else{
            navigate('/document/'+createdDocuments.at(0)?._id)
        }
        
    }

    const handleSubmitReview = (e) => {
        e&&e.preventDefault();
        createDocumentRequest()
    }

    useEffect(()=>{
        console.log('signerData',signerData);
        console.log('documentCreationRequestData',documentCreationRequestData);
        
    },[signerData, documentCreationRequestData])
    
    return (
        <div className='review-and-send-email-layout-page'>
            <br /> 
            <PrepareDocStepper step={4}/>
            <br /><br />
            <div className="email-body-container">
                
                <div style={{width: "30%", display:"flex", alignItems:"center"}}>
                    <LottiePlayer animationData = {animation} loop={true} style={{width:'100%', height: '100%'}}/>
                </div>
                <div style={{width: "70%"}}>
                    <h2>Review & Send Document</h2>
                    <br /><br />
                    <Grid container spacing={3} style={{padding: "2%"}} onChange={handleReviewInputChange}>
                        <Grid item xs={12} md={12}>
                            <TextField name="subject" value={reviewData.subject} className='subject-field' label="Subject" variant="outlined"/>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <textarea name="body" value={reviewData.body} className="email-text-area" placeholder="Email Body" />
                        </Grid>
                    </Grid>
                    <br />
                    <div>

                        <Button className="send-document-button" onClick={handleSubmitReview} variant="contained">Send Document</Button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default (ReviewSendEmail)
